import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
export default ({ data }) => (
  <Layout>
    <h1>{data.site.siteMetadata.title} İletişim</h1>
    <h3> Bize ulaşın </h3>
    <p>
    info@borankay.com   </p>

<p>Gsm: 0532 234 31 24</p>

<p>Gsm: 0533 731 33 15</p>

<p>Tel:  0252 358 66 98</p>

<p>Fax: 0252 358 66 97</p>
 
  </Layout>
)
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`